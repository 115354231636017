.card-container{
    width: 400px;
    height: 400px;
    overflow: hidden;
    box-shadow:0px 0px 15px -5px;
    transition: 0.5s;
    animation: ease-in-out;
    background-color:#E5E5E5;
}

.image-container img{
    overflow: hidden;
    height: 200px;
    width: 400px;
}

.card-container:hover{
    transform: scale(1.1);
    box-shadow:0px 0px 15px 0px;
}

.card-content{
    margin: 1rem;
    margin-top: 0.5rem;
}

h3,p{
    margin: 0;
    padding: 0;
    display: inline;
}

.card-title{
    margin-bottom: 0.5rem;

}